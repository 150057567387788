import React, { useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import {TbLogin, TbLogout} from 'react-icons/tb'
import { RoutePaths } from '../../constants/gameConstants'
import { logout } from '../../store/userSlice'
import { IRootState } from '../../models/models'
import useCloseEffect from '../../hooks/useCloseEffect'

const mapState = (state: IRootState) => ({
    token: state.user.token, 
    name: state.user.name,
    isDevice: state.topState.isDevice
})

const mapDispatch = {logout}

const connector = connect(mapState, mapDispatch)

type Props = ConnectedProps<typeof connector>

const LoginBarComponent = (props: Props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const {logout, token, name, isDevice} = props
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const isAuth = !!token
    const addListeners = useCloseEffect('user-menu-dropdown-toggle', setDropdownOpen, true)
    const handleLogout = (e: any) => {
        e.preventDefault()
        logout();
        setDropdownOpen(false)
        const prepath = location.pathname.split('/').slice(1).map(n => '../').join('')
        navigate(`${prepath}${RoutePaths.auth}`)
        
    }
    const onClick = () => {
        setDropdownOpen(!dropdownOpen)
        addListeners(true)
    }

    if (isAuth && !isDevice) {
        return (
            <div className='user-menu-wrapper is-auth'>
                <button 
                    className="user-menu-dropdown-toggle"
                    type="button" 
                    title="go to profile" 
                    onClick={onClick}
                >
                    {name}
                </button>
                <ul className={`user-menu-dropdown${dropdownOpen ? ' open' : ' hidden'}`} >
                    <li className='width-maker'><button>{name}</button></li>
                    <li title="logout" className="user-menu-logout">
                        <button 
                            className='navlink' 
                            type="button"
                            onClick={handleLogout}
                            onTouchStart={handleLogout}
                        >
                            <TbLogout />
                        </button>
                    </li>
                </ul>
            </div>
        )
    }
    return (
        isAuth
        ?  <li title="logout" className="user-menu-logout">
            <button 
                className='navlink' 
                type="button"
                onClick={handleLogout}
                onTouchStart={handleLogout}
            >
                <TbLogout />
            </button>
        </li>
        : <li className={`user-menu-login ${name ? '' : ' highlight'}`}>
            <NavLink className={`navlink ${name ? '' : ' highlight'}`} 
                title="login" 
                to={RoutePaths.auth}
            >
                <TbLogin />
            </NavLink> 
         </li>           
    )
}

export const LoginBar = connector(LoginBarComponent)
