import { useDispatch, useSelector } from "react-redux"
import { IRootState } from "../../models/models"
import { selectLanguage } from "../../store/userSlice"
import { I18n } from "../../assets/i18n"
import { useEffect, useState } from "react"
import { LuXSquare } from "react-icons/lu"
import { setOnboardingStep } from "../../store/topStateSlice"


export const StepIndicator = () => {
    const step = useSelector((state: IRootState) => state.topState.onboardingStep)
    const device = useSelector((state: IRootState) => state.topState.isDevice)
    const classInd = `onboarding-steps-indicator step-${step + 1}${device ? ' device' : ''}`
    const lg = useSelector(selectLanguage)
    const i18n = I18n[lg.slice(0,2)] || I18n.en
    const steps = [0, 1, 2].map(s => i18n[`step${s}Indicator`])
    return (
        <div className={classInd}>
            {steps.map((s, i) => {
                const classN = 'indicator' + (i === step ? ' actual' : '')
                return (
                    <div key={i} className="indicator-wrapper">
                        <div  className={classN}>{s}</div>
                        {i < steps.length - 1 ? <div className="dash"></div> : null}
                    </div>
                )
            })}
        </div>
    )
}

export const PuzzlesIndicator = (props: {level: number, success: number}) => {
    const levels = [0,1,2,3,4,5,6,7,8,9,10]
    const {level, success} = props
    return (
        <div className={"puzzles-indicator"}>
            {levels.map((s, i) => {
                const classN = `indicator${i === level ? ' current' : ''}${success === i ? ' success' : ''}`
                return (
                    <div className="indicator-wrapper" key={s}>
                        <div className={classN}>{s}</div>
                        {s < levels.length - 1 ? <div className="dash"></div> : null}
                    </div>
                )
            })}
        </div>
    )
}

export const LastStepModal = () => {
    const [show, setShow] = useState(true)
    const lg = useSelector(selectLanguage)
    // const user = useSelector(selectUser)
    const i18n = I18n[lg.slice(0,2)] || I18n.en
    const dispatch = useDispatch()
    // const navi = useNavigate()
    const close = () => {
        setShow(false)
        dispatch(setOnboardingStep(3))
    }
    useEffect(() => {
        document.addEventListener('click', close)
        document.addEventListener('touchstart', close)
        return () => {
            document.removeEventListener('click', close)
            document.removeEventListener('touchstart', close)
        }
    })
    if (!show) return null
    const texts = i18n.lastOnbModal.split('.')
    return (
        <div className="last-step-modal">
            <LuXSquare />
            {
                texts.map((t: string, i: number) => (
                    <p key={i}>{t + (i < texts.length - 1 ? '.' : '')}</p>)
                )
            }
        </div>
    )
}

export const SkipModal = ({close = () => {}, skip = () => {}}) => {
    const lg = useSelector(selectLanguage)
    const i18n = I18n[lg.slice(0,2)] || I18n.en
    const click = (e: any, q: number) => {
        e.preventDefault()
        console.log(q)
        if (!q) close()
        else skip()
    }
    
    return (
        <div className="skip-modal">
            <div className="skip-modal_text">
                {i18n.skipText}
            </div>
            <div className="skip-modal_footer">
                <button type="button" onClick={(e) => click(e, 1)}>{i18n.yes}</button>
                <button type="button" onClick={(e) => click(e, 0)}>{i18n.no}</button>
            </div>
        </div>
    )
}
