
import { useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Axios, setAuthorizationHeader } from "../../common/axios"
import { I18n } from "../../assets/i18n"
import validate from "../../common/helpers/inputValidation"

import { useMessageShow } from "../../hooks/useShowMessage"
import { IRef } from "../../models/models"

import { selectLanguage, selectMode, selectTheme, selectToken } from "../../store/userSlice"
import { isDev } from "../../local-engine/gameplay-helper-fn"
import { InputPassword } from "../../UI-components/Input/Input"
import { FadeIn } from "../../UI-components/FadeIn/FadeIn"

export const ResetPage: React.FC = () => {
    const [form, setForm] = useState({oldPassword: '', password: '', confirmPassword: ''})
    const submitted = useRef(false)
    const mode = useSelector(selectMode)
    const theme = useSelector(selectTheme)
    const token = useSelector(selectToken)
    const formRef: IRef<any> = useRef()
    const navigate = useNavigate()
    const {showMessage, fadeMessage} = useMessageShow()
    const language = useSelector(selectLanguage)
    // const dispatch = useDispatch()
    const i18n = I18n[language.slice(0, 2)] || I18n.en

    const handleReset = async () => {
        const {confirmPassword , ...rest} = form
        const body = JSON.stringify(rest)
        const url = `/api/auth/newpassword`
        submitted.current = true
        try {
            setAuthorizationHeader(token)
            const data: any = await Axios.post(url, body)
            if (data.status < 300) {
                navigate(`..`)
            }
            submitted.current = (false)
        } catch(e: any) {
            showMessage({msg: e.response?.data.message || e.message, ok: false})
            submitted.current = (false)
            console.error(e)
        } 
    }

    const handleBlur = (e: React.FocusEvent) => {
        e.preventDefault()
        const target = e.target as HTMLInputElement
        if (target.type === "submit") {
            return
        }
        const error = validate('password')(target.value)
        if (error.message) {
            showMessage({msg: error.message, ok: false})
            target.parentElement?.classList.add('error')
        }
        if (target.id === 'confirmPassword' && form.confirmPassword !== form.password) {
            showMessage({msg: 'New passwords are not the same', ok: false})
        }
    }

    const handleChanges = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault()
        const target = e.target as HTMLInputElement
        if (!target.parentElement) return
        fadeMessage()
        if (target.value) {
            target.parentElement.classList.add('dirty')
        } else {
            target.parentElement.classList.remove('dirty')
        }
        const error = validate('password')(target.value);
        if (error.message) {
            showMessage({msg: error.message, ok: false})
            target.parentElement.classList.add('error')
        } else {
            target.parentElement.classList.remove('error')
        }
        setForm(form => ({...form, [target.name]: target.value}))
    }
    
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        isDev() && console.log(
            form, 
            submitted, 
            (e.target as HTMLFormElement).querySelector('.error')
        )
        if (submitted.current) return
        const target = e.target as HTMLFormElement
        const errors = target.querySelector('.error')
        if (errors || !form.oldPassword || !form.password || !form.confirmPassword) {
            return
        }
        handleReset()
    }

    const formMessage = i18n.resetMess || 'Please set your new password'
    return (
        <FadeIn>
        <div className={`auth-page mode-${mode} theme-${theme}`}>
            <div className="form-wrapper " >
                <div className="form-title-wrapper">
                    <h2>{formMessage}</h2>
                </div>
                <form onBlur={handleBlur} onSubmit={handleSubmit} ref={formRef} autoComplete="off">                 
                    <InputPassword 
                        label={i18n.labelOldPassword || 'Old password'}
                        id="oldPassword"
                        name="oldPassword"
                        value={form.oldPassword}
                        handleChanges={handleChanges}
                    />
                    <InputPassword 
                        label={i18n.labelNewPassword || 'New password'}
                        id="password"
                        name="password"
                        value={form.password}
                        handleChanges={handleChanges}
                    />
                    <InputPassword 
                        label={i18n.labelNewPassword || 'New password'}
                        id="confirmPassword"
                        name="confirmPassword"
                        value={form.confirmPassword}
                        handleChanges={handleChanges}
                    />
                    <button type="submit" value={i18n.submitButton} className="noselect">{i18n.submitButton}</button>
                </form>
            </div>
        </div>
        </FadeIn>
    )
}

export default ResetPage
