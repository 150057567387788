import { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { GrHomeOption } from "react-icons/gr";
import { MdOutlineSettings } from "react-icons/md";
import { TbInfoSquare } from "react-icons/tb";
import { PiMagnifyingGlassPlusBold } from "react-icons/pi";
import { TbPuzzle2 } from "react-icons/tb";
import { RoutePaths } from '../../constants/gameConstants'
import { FiMenu }  from 'react-icons/fi'
import { LoginBar } from './LoginBar'
// import { Logo } from '../Logo/LogoIcon'
import useCloseEffect from '../../hooks/useCloseEffect'
import useDevice from '../../hooks/useDevice'
import { selectUserWithProps } from '../../store/rootState&Reducer'
import { LanguageSwitcher } from '../CtrlBtns/CtrlBtn'
import { MdOutlinePerson4 } from 'react-icons/md'
import { RiFeedbackLine } from "react-icons/ri";
import { RxTransparencyGrid } from 'react-icons/rx';


export const Navbar = () => {
    const {token, name, exp} = useSelector(selectUserWithProps(['token', 'name', 'exp']))
    const [isAuth, setIsAuth] = useState(!!token)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const location = useLocation()
    const device = useDevice()
    const addListeners = useCloseEffect('navbar-device-menu-btn', setDropdownOpen, device)

    useEffect(() => {
        if (!device && !dropdownOpen) {
            setDropdownOpen(true)
        } else if (device && dropdownOpen) {
            setDropdownOpen(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device])

    useEffect(() => {
        setIsAuth(!!token)
    }, [token])

    useEffect(() => {
        setDropdownOpen(false)
    }, [location.pathname])
    
    const className = `${dropdownOpen ? 'device-menu' : 'hidden'}`
    const liClass = `navlink${!name ? ' no-name' : ''}`

    return (
        <div className={`nav-wrapper`} >
        {!exp.onboardingPassed ? <LanguageSwitcher /> : null}
        <nav className={`navbar${exp.onboardingPassed ? '' : ' no-name'}`}>
            <button
                className={`navbar-device-menu-btn${!device ? ' hidden' : ''}`}
                type="button"
                onClick={() => {setDropdownOpen(!dropdownOpen); addListeners(true);}}
                aria-controls="navbarContent"
                aria-expanded={dropdownOpen ? "true" : "false"}
                aria-label="Toggle navigation"
            >
                <FiMenu />
            </button>
            <ul 
                className={`${!device ? '' : className}`}
                id="navbarContent"
            >
                <li title="home">
                    <NavLink className={'navlink'} to={RoutePaths.home} >
                        <GrHomeOption />
                    </NavLink>
                </li>
                {
                    name && exp.onboardingPassed
                        ? <>
                            <li title="about">
                                <NavLink className={liClass} to={RoutePaths.about} >
                                    <TbInfoSquare />
                                </NavLink>
                            </li>
                            <li title="puzzles">
                                <NavLink className={liClass} to={RoutePaths.puzzles} >
                                    <TbPuzzle2 />
                                </NavLink>
                            </li>
                            <li title="game">
                                <NavLink className={liClass} to={RoutePaths.game} >
                                    <RxTransparencyGrid />
                                </NavLink>
                            </li>
                            <li title="settings">
                                <NavLink className={liClass} to={RoutePaths.settings} >
                                    <MdOutlineSettings />
                                </NavLink>
                            </li>
                            <li title="feedback">
                                <NavLink className={liClass} to={RoutePaths.feedback} >
                                    <RiFeedbackLine />
                                </NavLink>
                            </li>
                            <li title='profile'>
                                <NavLink className={liClass} to={RoutePaths.profile} >
                                    <MdOutlinePerson4 />
                                </NavLink>
                            </li>
                        </>
                        : null
                }
                {
                    isAuth && (
                        <li title={`analysis`}>
                            <NavLink  className="navlink" to={RoutePaths.analysis}>
                                <PiMagnifyingGlassPlusBold />
                            </NavLink>
                        </li>
                    )
                }
                {!isAuth ? <LoginBar /> : null}
            </ul>
            {isAuth ? <LoginBar /> : null}
        </nav>
        </div>
    )
}

export default Navbar
{/* <Logo /><TbGridDots /> */}