import { delay, put, select, takeLatest } from 'redux-saga/effects';
import { setGameVariant } from '../gameOptionsSlice'

import { moveR } from '../../local-engine/move-r';
import { IMoves, IRootState } from '../../models/models';
import { setBoardSize, turnBoard, updateBoardState } from '../boardSlice';
import { UnknownAction } from 'redux';
import { copyObj } from '../../local-engine/gameplay-helper-fn';
import { createCellsMap, updatePiecesPosition } from '../../local-engine/board-helper-fn';
import { updateGame } from '../gameSlice';
import { setView } from '../userSlice';
import { BaseBoardSize, InternationalBoardSize } from '../../constants/gameConstants';


function* workerGameVariant(action: UnknownAction) {
    const {
        board: {boardSize: bs, cellSize: cS, reversedBoard},
        user: {towerView},
    } = (yield select()) as IRootState
    let {GV, boardSize} = action.payload as any
    boardSize = boardSize || (GV === 'international'
        ? InternationalBoardSize 
        : BaseBoardSize)
   
    moveR.setProps({GV, size: boardSize})
    if (GV === 'towers' && towerView !== 'face') {
        yield put(setView('face'))
    }
    // isDev() && console.warn(moveResolver.size, moveResolver.board)
    const cellSize = boardSize !== bs ? Math.round(cS * bs/boardSize/10)*10  : cS
    const cellsMap = createCellsMap(boardSize, cellSize, reversedBoard)
    yield put(updateBoardState({cellSize, cellsMap, boardSize}))
}

function* workerBoardSize(action: UnknownAction) {
    const {
        board: {boardSize: bs, cellSize: cS, reversedBoard},
        gameSettings: {gameVariant: GV},
    } = (yield select()) as IRootState
    const boardSize = action.payload as number
    moveR.setProps({GV, size: boardSize})
    const cellSize = boardSize !== bs ? Math.round(cS * bs/boardSize/10)*10  : cS
    const cellsMap = createCellsMap(boardSize, cellSize, reversedBoard)
    yield put(updateBoardState({cellSize, cellsMap, boardSize}))
    
}

function* workerTurnBoard(action: UnknownAction) {
    const revBoard = action.payload as boolean
    const {
        board: {boardSize, cellSize}, 
        game: {position: pos, moves: ms}} = (yield select()) as IRootState
    const cellsMap = createCellsMap(boardSize, cellSize, revBoard)
    yield delay(50)
    const position = updatePiecesPosition(pos, cellsMap, cellSize)
    const moves = (copyObj(ms) as IMoves).map(move => {
        if (move.black) {
            move.black.position = updatePiecesPosition(move.black.position, cellsMap, cellSize)
        }
        move.white!.position = updatePiecesPosition(move.white!.position, cellsMap, cellSize)
        return move
    })
    yield put(updateGame({position, moves}))
    yield put(updateBoardState({cellsMap}))
}

export default function* watcherPreGame() {
    yield takeLatest(turnBoard, workerTurnBoard)
    yield takeLatest(setBoardSize, workerBoardSize)
    yield takeLatest(setGameVariant, workerGameVariant)
}
