import { createStartPosition } from "../local-engine/board-helper-fn"
import { movesFromSolution } from "../local-engine/gameplay-helper-fn"
import MoveResolver from "../local-engine/move-resolver"
import { GameVariant } from "../models/models"

// console.error(moves.reduce((acc, m) => {
//     acc.push(m.white.move)
//     if (m.black) {
//         acc.push(m.black.move)
//     }
//     return acc
// },[] as string[]))

const pos = createStartPosition()
export const demoM1 = ['e3-d4', 'h6-g5', 'g3-f4', 'g5xe3xc5', 'c3-d4', 'c5xe3', 'f2xd4', 'f6-e5', 'd4xf6', 'e7xg5', 'h2-g3', 'f6-e5', 'g1-h2', 'e5-f4', 'g3xe5', 'd6-c5', 'e5xg3', 'c5-b4', 'a3xc5', 'b6xd4xf2xh4', 'h2xf4xh6', 'h4xf6', 'e3-d4', 'c5xe3', 'd2xf4xh2', 'c7-d6', 'd4-e5', 'd6xf4xd2', 'c1xe3', 'b8-c7', 'e3-f4', 'c7-d6', 'd2-c3', 'a7-b6', 'c3-d4', 'b6-c5', 'd4xb6', 'f6-e5', 'f4-g5', 'd8-e7', 'b6-a7', 'g7-f6', 'a7-b8', 'f6xh4', 'h6xf4', 'e5xg3', 'b8xe5', 'f4xd6', 'h2xf4', 'h4xf2', 'e5xc7', 'd6xb8', 'b2-c3', 'f2xh4', 'f4-e5', 'h4xf2', 'c3-d4', 'f2xh4', 'a1-b2', 'h4xf2', 'e1xg3', 'c7-d6', 'e5xc7', 'h8-g7', 'c7xe5', 'd6xf4xh2', 'd4xf6xh8', 'g3xe1', 'h8xc3', 'e1xb4', 'b2xd4xf6xd8', 'f8-e7', 'd8xf6xd4xa1', 'e5-f4', 'a1xe5xg3', 'h2xf4', 'f2xh4', 'g3-h2', 'h4-g5', 'f4xh6', 'g5-f6', 'h2-g1', 'f6-g7', 'h6xf8', 'g7-h8', 'b8-a7', 'h8-a1', 'f8-g7', 'a1xh8', 'b4-f8']
export const demoM2 = ['e3-d4', 'd6-e5', 'c3-b4', 'e5xc3xa5', 'a3-b4', 'a5xc3', 'b2xd4', 'e7-d6', 'd4-c5', 'd6xb4', 'c3xa5', 'c5xa3', 'd2-c3', 'f6-g5', 'c3-b4', 'a3xc5', 'c1-b2', 'd8-e7', 'f2-e3', 'g5-h4', 'e1-d2', 'h4xf2xd4', 'b2-c3', 'd4xb2', 'a1xc3', 'g7-f6', 'b2-a3', 'f8-g7', 'a3-b4', 'c5xa3', 'h2-g3', 'a3xc5', 'b4xd6xf8', 'b6xd4xb2', 'd2xb4', 'b2-c1', 'c5-b6', 'a7xc5xa3', 'b6xd8xh4', 'b4-c3', 'g3-f4', 'c1xg5', 'h4xf6xa1', 'h6xf4', 'g5xe3', 'g7-h6', 'f4-g5', 'h6xf4xd2', 'e3xc1', 'b8-c7', 'a5-b6', 'c7xa5', 'a1-b2', 'a5xc7', 'b6xd8', 'h8-g7', 'f8xh6']
export const demoM0 = ['c3-b4', 'b6-c5', 'b4-a5', 'a7-b6', 'b2-c3', 'c5-b4', 'a3xc5xa7', 'c7-b6', 'a5xc7xe5', 'f6xd4xb2', 'a1xc3', 'd8-c7', 'g3-f4', 'e5xg3', 'h2xf4', 'g3xe5', 'e3xg5', 'h6xf4', 'd2-e3', 'f4xd2xb4', 'b2xd4xf6xd8xa5']
export const demoM3 = ['e3-f4', 'd6-e5', 'f4xd6', 'e7xc5', 'c3-b4', 'd6-e5', 'b4xd6xf4', 'b6xd4', 'd2-e3', 'c7-b6', 'e3xc5', 'f6-e5', 'f4xd6', 'd8-c7', 'd4-e5', 'b6xd4xf6', 'd6xb4', 'c7-b6', 'b2-c3', 'b8-c7', 'g3-f4', 'c7-d6', 'h2-g3', 'b6-c5', 'e1-d2', 'a7-b6', 'a1-b2', 'b6-a5', 'g3-h4', 'd6-e5', 'f4xd6', 'c5xe7', 'f2-e3', 'd6-c5', 'b4xd6', 'e7xc5', 'g1-f2', 'f6-e5', 'f2-g3', 'g7-f6', 'e3-f4', 'f8-e7', 'd2-e3', 'c5-d4', 'e3xc5', 'd6xb4xd2', 'f4xd6xf8', 'c5xe3', 'd4xf2', 'd2xf4xh2', 'e5xg7', 'h8xf6', 'g7xe5', 'h2-g1', 'f2-g3', 'g1-c5', 'f8xb4', 'a5xc3xa1', 'c1-d2', 'b4-c3', 'd2xb4xd6', 'c5xe7xg5', 'e5xc7', 'd6xb8', 'h4xf6', 'g5xe7', 'g3-h4', 'h6-g5', 'a3-b4', 'c3xa5']
const moveR = new MoveResolver({size: 8})

export const Demos = [
  movesFromSolution(demoM1, pos, moveR),
  movesFromSolution(demoM2, pos, moveR),
  movesFromSolution(demoM0, pos, moveR),
  movesFromSolution(demoM3, pos, moveR),
]

export const getDebutes = (gv: GameVariant) => Debutes[gv]

const Debutes: {[key: string]: {[key: string]: any}} = {
    russian: {
        'c3-d4_f6-g5_g3-h4': {
            name: 'attack g5', 
            moves: ['c3-d4', 'f6-g5', 'g3-h4'], 
            continue: {}
        },
        'c3-d4_f6-e5_d4:f6_g7:e5_a3-b4': {
            name: 'subversive beginnig',
            moves: ['c3-d4', 'f6-e5', 'd4:f6', 'g7:e5', 'a3-b4'],
            continue: {}
        },
        'c3-d4_d6-e5_g3-f4': {
            name: 'fork', 
            moves: ['c3-d4', 'd6-e5', 'g3-f4'],
            continue: {}
        },
        'c3-d4_d6-c5_b2-c3_f6-g5_c3-b4_g7-f6_b4:d6_e7:c5': {
            name: 'town game with g7-f6',
            moves: ['c3-d4', 'd6-c5', 'b2-c3', 'f6-g5', 'c3-b4', 'g7-f6', 'b4:d6', 'e7:c5']
        },
        'c3-d4_d6-c5_b2-c3_f6-g5_c3-b4_b6-a5': {
            name: 'town game with b6-a5',
            moves: ['c3-d4', 'd6-c5', 'b2-c3', 'f6-g5', 'c3-b4', 'b6-a5']
        },
        'c3-d4_d6-c5_d2-c3_f6-g5_c3-b4_g5-h4': {
            name: 'town game with d2-c3',
            moves: ['c3-d4', 'd6-c5', 'd2-c3', 'f6-g5', 'c3-b4', 'g5-h4']
        },
        'c3-d4_d6-c5_b2-c3_f6-g5_c3-b4_g5-f4': {
            name: 'town game with g5-f4',
            moves: ['c3-d4', 'd6-c5', 'b2-c3', 'f6-g5', 'c3-b4', 'g5-f4']
        },
        'a3-b4_h6-g5_g3-h4_g5-f4': {
            name: 'double pole',
            moves: ['a3-b4', 'h6-g5', 'g3-h4', 'g5-f4']
        },
        'c3-d4_f6-g5_d4-c5_d6:b4_a3:c5_b6:d4_e3:c5_g5-f4_g3:f5_c7-b6': {
            name: 'kukuev gambit d4',
            moves: ['c3-d4', 'f6-g5', 'd4-c5', 'd6:b4', 'a3:c5', 'b6:d4', 'e3:c5', 'g5-f4', 'g3:f5', 'c7-b6']
        },
        'c3-b4_f6-g5_b4-c5_d6:b4_a3:c5_b6:d4_e3:c5_g5-f4_g3:f5_c7-b6': {
            name: 'kukuev gambit b4',
            moves: ['c3-d4', 'f6-g5', 'd4-c5', 'd6:b4', 'a3:c5', 'b6:d4', 'e3:c5', 'g5-f4', 'g3:f5', 'c7-b6']
        },
    }
}
// const moves = 